<template>
    <div class="register-select--view">
        <div class="register--form">
            <h1>Выберите роль</h1>
            <div class="select--container">
                <div class="img--container" @click="selectRole(0)">
                    <img src="../../assets/icons/user.svg" class="user__img">
                    <div class="selected--border" v-if="selectedRole === 0"></div>
                </div>
                <div class="img--container" @click="selectRole(1)">
                    <img src="../../assets/icons/multi.svg" class="multi__img">
                    <div class="selected--border" v-if="selectedRole === 1"></div>
                </div>
            </div>
            <div class="buttons--container">
                <button class="btn btn__close" @click="goToMain">ОТМЕНА</button>
                <button class="btn btn__action" @click="goToSelectedRole" :class="{'not_available' : this.selectedRole === -1}">ДАЛЕЕ</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RegisterSelectView",
        data(){
            return {
                selectedRole: -1
            }
        },
        methods:{
            selectRole(roleIndex){
                if (roleIndex === this.selectedRole){
                    this.selectedRole = -1
                } else {
                    this.selectedRole = roleIndex
                }
            },
            goToSelectedRole(){
                if (this.selectedRole === 0){
                    this.$router.push({name : 'RegisterUserView'})
                } else if (this.selectedRole === 1){
                    this.$router.push({name : 'RegisterMultiAccountView'})
                }
            },

          goToMain(){
              this.$router.push({name: "MainView"})
          }
        }
    }
</script>

<style scoped lang="scss">
    .register-select--view{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .register--form{
            background: #FFFFFF;

            @include for-desktop-up{
                width: 400px;
                height: 300px;
                box-shadow: 0 1px 4px 0 rgba(0,0,0,0.14);
                border-radius: 4px;
            }

            @include for-big-desktop-up{
                width: 528px;
                height: 396px;
              background: #FFFFFF;
              box-shadow: 0 1px 5px 0 rgba(0,0,0,0.14);
              border-radius: 5px;
            }


            h1{
                margin-left: 35px;
                margin-top: 26px;
                font-family: Roboto, "sans-serif";
                font-size: 16px;
                color: #2C2C2C;
                letter-spacing: 0;

                @include for-desktop-up{
                    margin-left: 27px;
                    margin-top: 20px;
                    font-size: 12px;
                }
            }

            .select--container{
                margin-left: calc(88px - 28px);
                margin-top: 53px;
                display: flex;

                @include for-big-desktop-up{
                    margin-left: calc(116px - 36px);
                    margin-top: 68px;
                }

                img{
                    width: 98px;
                    height: 105px;
                    border-radius: 8px;
                    cursor: pointer;
                    object-fit: cover;

                    @include for-big-desktop-up{
                        width: 130px;
                        height: 139px;
                        border-radius: 9px;
                    }
                }


                .img--container{
                    position: relative;
                    width: 98px;
                    height: 105px;
                    border-radius: 8px;
                    overflow: hidden;
                    margin-left: 28px;
                  transition: all .3s;

                  &:hover{
                    transform: scale(0.95);
                  }


                    @include for-big-desktop-up{
                      margin-left: 36px;
                      width: 130px;
                      height: 139px;
                      border-radius: 9px;
                    }
                }
                .selected--border{
                    position: absolute;
                    top: 0;
                    right: 0;
                    border: 6px solid #6F64F8 ;
                    background-color: rgba(0,0,0,.1);
                  border-radius: 9px;

                    width: 98px;
                    height: 105px;

                    @include for-big-desktop-up{
                      width: 130px;
                      height: 139px;
                    }

                    @include for-big-desktop-up{
                        border: 9px solid #6F64F8 ;
                    }
                }
            }
            .buttons--container{
                margin-top: 67px;
                display: flex;
                justify-content: flex-end;
                margin-right: 20px;

                @include for-big-desktop-up{
                    margin-top: 89px;
                    margin-right: 26px;
                }


                .btn{
                    width: 100px;
                    height: 21px;
                    background: #6F64F8;
                    border-radius: 4px;
                    font-family: Roboto, "sans-serif";
                    font-size: 8px;
                    letter-spacing: 0;
                    border: 0;
                    outline: 0;
                    cursor: pointer;

                    @include for-big-desktop-up{
                        width: 132px;
                        height: 28px;
                        border-radius: 5px;
                        font-size: 12px;
                        letter-spacing: 0;
                    }

                    &:hover{
                        filter: brightness(87.5%);
                    }

                    &.not_available{
                        pointer-events: none;
                        opacity: .5;
                    }
                }

                .btn__action{
                    background: #6F64F8;
                    color: #FFFFFF;
                }

                .btn__close{
                    background: #EDEDED;
                    color: #2C2C2C;
                    margin-right: 15px;

                    @include for-big-desktop-up{
                        margin-right: 30px;
                    }
                }
            }
        }
    }
</style>
